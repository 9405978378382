<template>
  <div class="util__summary__top__left">
    <div class="summary__stat__card">
      <h5>Utilization Rate</h5>
      <div class="summary__stat__card__info">
        <div class="summary__stat__card__info__texts">
          <h4>{{ rate }}%</h4>
          <h6><BaseIcon icon="arrow/up" width="9" height="9" /> +16.73%</h6>
        </div>
        <div class="summary__stat__card__info__cover">
          <div class="summary__stat__card__info__rang" :style="style"></div>
        </div>
      </div>
    </div>
    <div class="summary__stat__card">
      <h5>Typical Hours</h5>
      <h4>{{ typicalHours }}</h4>
    </div>
    <div class="summary__stat__card">
      <h5>AVG {{ groupByRep }} Usage</h5>
      <h4>{{ usageHours }}</h4>
    </div>
    <div class="summary__stat__card">
      <h5>AVG {{ groupByRep }} Idle Hours</h5>
      <h4>{{ idleHours }}</h4>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import store from "@/store";
import BaseIcon from "@/components/svg/BaseIcon";
import { helpers } from "@/composables/helpers";

export default defineComponent({
  name: "DeviceUtilizationStats",
  components: { BaseIcon },
  setup() {
    const { convertHrsToMins, convertToHrs } = helpers();

    const device = computed(() => {
      return store.getters["devices/device"];
    });

    const groupBy = computed(() => {
      return store.getters["devices/utilGroupBy"];
    });

    const rep = {
      day: "daily",
      week: "weekly",
      month: "monthly",
    };

    const groupByRep = computed(() => {
      return rep[groupBy.value];
    });
    const utilizationRates = computed(() => {
      return store.getters["analytics/utilizationRates"];
    });

    const typicalHours = computed(() => {
      const from = utilizationRates.value.hours.from;
      const to = utilizationRates.value.hours.to;

      if (!from && !to) return "-";

      return `${from || "N/A"} - ${to || "N/A"}`;
    });

    const usageHours = computed(() => {
      const usage = utilizationRates.value.hours.usage;
      return convertHrsToMins(usage);
    });

    const idleHours = computed(() => {
      const idle = utilizationRates.value.hours.idle;
      return convertHrsToMins(idle);
    });

    const rate = computed(() => {
      const idleHrs = convertToHrs(utilizationRates.value.hours.idle);
      const usageHrs = convertToHrs(utilizationRates.value.hours.usage);

      return Math.round((usageHrs / (idleHrs + usageHrs)) * 100);
    });

    const style = {
      width: `${rate.value}%`,
    };

    return {
      device,
      usageHours,
      idleHours,
      typicalHours,
      rate,
      style,
      groupByRep,
    };
  },
});
</script>
