<template>
  <div class="device-view__body__util__summary">
    <div class="util__summary__top">
      <DeviceUtilizationStats />
      <DeviceUtilizationUsageChart />
    </div>
    <!--    <div class="util__summary__distribution">-->
    <!--      <h6>Hours Distribution</h6>-->
    <!--      <UtilDistributionChart />-->
    <!--    </div>-->
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import store from "@/store";
import DeviceUtilizationStats from "@/components/devices/utilization/DeviceUtilizationStats";
import DeviceUtilizationUsageChart from "@/components/devices/utilization/DeviceUtilizationUsageChart";

export default defineComponent({
  name: "DeviceUtilizationSummary",
  components: { DeviceUtilizationUsageChart, DeviceUtilizationStats },
  setup() {
    const currentType = ref(null);
    const currentEvent = ref(null);

    const device = computed(() => {
      return store.getters["devices/device"];
    });

    const deviceEvents = computed(() => {
      return store.getters["devices/deviceEvents"];
    });

    return {
      device,
      currentType,
      currentEvent,
      deviceEvents,
    };
  },
});
</script>
