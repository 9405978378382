import moment from "moment";

export function helpers() {
  const serialize = (obj: any) => {
    const str: any = [];
    for (const p in obj)
      if (obj.p) {
        if (encodeURIComponent(obj[p]))
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };

  const split = (str) => {
    if (!str) return null;
    str = str.split("_").join(" ");
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const transformLabel = (str: string) => {
    if (!str) return null;

    if (str === "in_repair") str = "repair";
    str = str.replace("_", "-");

    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getFirstLetter = (str: string | null | undefined): string => {
    if (!str) return "";
    return str.charAt(0);
  };

  const removeFirstSubstringAndTrim = (str: string): string => {
    const spaceIndex = str.indexOf(" ");
    if (spaceIndex === -1) {
      return str.trim();
    }
    return str.substring(spaceIndex + 1).trim();
  };

  const dateIsPresent = (date) => {
    return moment(date).isAfter(moment().format("YYYY-MM-DD"));
  };

  const formatDate = (date) => {
    if (!date) return null;
    return moment(date).format("MMM DD, YYYY");
  };

  const formatDate2 = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  const getUserFullName = (user) => {
    return user ? `${user.first_name} ${user.last_name}` : "-";
  };

  const addStatusClass = (value, field = "status") => {
    const devicesStatuses = [
      "available",
      "in_use",
      "unavailable",
      "dirty",
      "in_repair",
    ];
    if (field === "status") {
      if (["Issued", "open", "pending assignment"].includes(value)) {
        return "table__status__danger";
      } else if (["Collected", "Ended", "pending review"].includes(value)) {
        return "table__status__warning";
      } else if (
        [
          "Repaired",
          "Active",
          "closed",
          "work in progress",
          ...devicesStatuses,
        ].includes(value)
      ) {
        return "table__status__success";
      } else if (["on hold", "ready for work"].includes(value)) {
        return "table__status__grey";
      }
    }
    return "";
  };

  const sliceString = (str: string, num = 12) => {
    if (str.length <= num) return str;
    return str.slice(0, num) + "...";
  };

  const formatMoney = (
    amount,
    decimalCount = 0,
    decimal = ".",
    thousands = ","
  ) => {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - parseInt(i))
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  };

  const diffForHumans = (date: string) => {
    return moment(date).fromNow();
  };

  const diffForHumans2 = (date) => {
    date = new Date(date);

    const timeDifference = Math.abs(Date.now() - date.getTime());

    // Define the conversion factors for different units
    const minuteInMs = 60 * 1000;
    const hourInMs = 60 * minuteInMs;
    const dayInMs = 24 * hourInMs;
    const weekInMs = 7 * dayInMs;

    // Calculate the difference in different units
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(timeDifference / minuteInMs);
    const hoursDifference = Math.floor(timeDifference / hourInMs);
    const daysDifference = Math.floor(timeDifference / dayInMs);
    const weeksDifference = Math.floor(timeDifference / weekInMs);

    if (weeksDifference > 0) {
      return `${weeksDifference} week${weeksDifference === 1 ? "" : "s"} ago`;
    } else if (daysDifference > 0) {
      return `${daysDifference} day${daysDifference === 1 ? "" : "s"} ago`;
    } else if (hoursDifference > 0) {
      return `${hoursDifference} hour${hoursDifference === 1 ? "" : "s"} ago`;
    } else if (minutesDifference > 0) {
      return `${minutesDifference} minute${
        minutesDifference === 1 ? "" : "s"
      } ago`;
    } else {
      return `${secondsDifference} second${
        secondsDifference === 1 ? "" : "s"
      } ago`;
    }
  };

  const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }
    return true;
  };

  const areAllElementsExist = (arr1, arr2) => {
    // Check if every element of arr1 exists in arr2
    return arr1.every((item) => arr2.includes(item));
  };

  const convertHrsToMins = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    let time = "";

    time += `${hours} ${hours > 1 ? "hrs" : "hr"} `;
    time += `${mins} ${mins > 1 ? "mins" : "min"} `;

    return time;
  };

  const convertToHrs = (minutes: number): number => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return hours + mins / 60;
  };

  const replaceKeysWithEmptyString = (obj: any, keysToReplace: string) => {
    const result = { ...obj };
    for (const key of keysToReplace) {
      if (key in result) {
        result[key] = "";
      }
    }
    return result;
  };

  return {
    formatMoney,
    addStatusClass,
    diffForHumans,
    sliceString,
    serialize,
    convertToHrs,
    getUserFullName,
    getFirstLetter,
    removeFirstSubstringAndTrim,
    dateIsPresent,
    split,
    formatDate,
    areAllElementsExist,
    formatDate2,
    areArraysEqual,
    transformLabel,
    convertHrsToMins,
    diffForHumans2,
    replaceKeysWithEmptyString,
  };
}
