import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { DevicesState } from "@/store/modules/devices/types";
import AxiosClient from "@/services/api";
const BASE_URL = "/v1";
import router from "@/router";
import { RouteFilters } from "@/store/modules/filters/pages";
import { FilterScopeEnums } from "@/store/modules/filters/types";
import store from "@/store";

export const actions: ActionTree<DevicesState, RootState> = {
  getDevices(
    { state, commit, rootState },
    {
      pagination = { limit: 25, offset: 0 },
      sort = null,
      infinite = false,
      set = true,
    } = {}
  ) {
    const currentRoute: any = router.currentRoute;
    const routeName = currentRoute._value.name;
    const allowedFilters = RouteFilters[routeName]?.allowedFilters || [];

    if (!pagination) pagination = { limit: 25, offset: 0 };
    let url = `/devices?limit=${pagination.limit}&offset=${pagination.offset}&`;

    if (sort && sort.order_by && sort.order_direction) {
      url += `order_by=${sort.order_by}&order_direction=${sort.order_direction}&`;
    }
    const root: any = rootState;
    const floorNumber = root.locations?.floor?.floor_number;

    const filter = this.getters["filters/filters"];
    const searchString = this.getters["filters/searchString"];

    if (
      filter.subCategory &&
      filter.subCategory.id &&
      allowedFilters.includes(FilterScopeEnums.SUBCATEGORY)
    ) {
      url += `subcategory_id=${filter.subCategory.id}&`;
    } else if (filter.category && filter.category.id) {
      url += `category_id=${filter.category.id}&`;
    } else if (filter.department && filter.department.id) {
      url += `department_id=${filter.department.id}&`;
    }
    if (
      filter.vendor &&
      filter.vendor.id &&
      allowedFilters.includes(FilterScopeEnums.VENDOR)
    ) {
      url += `vendor_id=${filter.vendor.id}&`;
    }
    if (
      filter.reconciliationStatuses &&
      filter.reconciliationStatuses.length > 0 &&
      allowedFilters.includes(FilterScopeEnums.RECONCILIATION_STATUSES)
    ) {
      url += `reconciliation_status=${filter.reconciliationStatuses.join()}&`;
    }
    if (
      typeof floorNumber === "number" &&
      allowedFilters.includes(FilterScopeEnums.FLOOR_NUMBER)
    ) {
      url += `floor_number=${floorNumber}&`;
    }
    if (
      filter.deviceStatuses &&
      allowedFilters.includes(FilterScopeEnums.DEVICE_STATUSES)
    ) {
      url += `status=${filter.deviceStatuses}&`;
    }
    if (searchString) {
      url += `like_serial_number=${searchString}&`;
    }

    return AxiosClient.get(`${BASE_URL}${url}`)
      .then((res) => {
        const devices: any = state.devices;
        if (infinite) {
          res.data.data = [...devices.data, ...res.data.data];
        }
        if (set) {
          commit("SET_DEVICES", res.data);
        }
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
  getDepartments({ commit }, options) {
    return AxiosClient.get(`${BASE_URL}/departments?status=available`, {
      params: options,
    })
      .then((res) => {
        commit("SET_DEPARTMENTS", res.data.data);
        return res.data.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
  getHospitals({ commit }, options) {
    return AxiosClient.get(`${BASE_URL}/hospitals`, {
      params: options,
    })
      .then((res) => {
        commit("SET_HOSPITALS", res.data.data);
        return res.data.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
  getVendors({ commit }, options) {
    return AxiosClient.get(`${BASE_URL}/vendors`, { params: options })
      .then((res) => {
        commit("SET_VENDORS", res.data.data);
        return res.data.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
  getCategories({ commit }, params) {
    const { departmentId } = params;
    return AxiosClient.get(
      `${BASE_URL}/departments/${departmentId}/categories`,
      {
        params,
      }
    )
      .then((res) => {
        commit("SET_CATEGORIES", res.data.data);
        return res.data.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
  getSubCategories({ commit }, params) {
    const { departmentId, categoryId } = params;
    return AxiosClient.get(
      `${BASE_URL}/departments/${departmentId}/categories/${categoryId}/subcategories`,
      { params }
    )
      .then((res) => {
        commit("SET_SUB_CATEGORIES", res.data.data);
        return res.data.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  getDevice({ commit }, deviceId) {
    return AxiosClient.get(`${BASE_URL}/devices/${deviceId}`)
      .then((res) => {
        commit("SET_DEVICE", res.data);
        return res.data.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  updateDevice(_, { deviceId, data }) {
    return AxiosClient.put(`${BASE_URL}/devices/${deviceId}`, data)
      .then((res) => {
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  updateDeviceStatus(_, { deviceId, status }) {
    return AxiosClient.post(`${BASE_URL}/devices/${deviceId}/status`, {
      status,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  getReconciliationDeviceEvents({ commit }, deviceId) {
    return AxiosClient.get(
      `${BASE_URL}/devices/${deviceId}/events/reconciliation`
    )
      .then((res) => {
        const events = res.data.data;
        let event = null;
        if (events.length > 0) {
          event = events[events.length - 1];
        }
        commit("SET_DEVICE_RECONCILIATION_DEVICE", event);
        return res.data.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  async getDeviceEvents({ commit, dispatch }, deviceId) {
    const [biomedEvents, financialEvents, locationEvents, utilizationEvents] =
      await Promise.all([
        dispatch("getDeviceTypeEvents", { deviceId, type: "biomed" }),
        dispatch("getDeviceTypeEvents", { deviceId, type: "financial" }),
        dispatch("getDeviceTypeEvents", { deviceId, type: "location" }),
        dispatch("getDeviceTypeEvents", {
          deviceId,
          type: "utilization",
        }),
      ]);

    const events = {
      biomed: biomedEvents,
      financial: financialEvents,
      location: locationEvents,
      utilization: utilizationEvents,
    };

    commit(`SET_DEVICE_EVENTS`, events);

    return events;
  },

  getDeviceTypeEvents({ commit }, { deviceId, type }) {
    return AxiosClient.get(`${BASE_URL}/devices/${deviceId}/events/${type}`)
      .then((res) => {
        commit(`SET_DEVICE_${type.toUpperCase()}_EVENTS`, res.data.data);
        return res.data.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  getReconciliationCount({ commit }) {
    const filter = this.getters["filters/filters"];
    let url = `/devices/reconciliation?`;

    if (filter.subCategory && filter.subCategory.id) {
      url += `subcategory_id=${filter.subCategory.id}&`;
    } else if (filter.category && filter.category.id) {
      url += `category_id=${filter.category.id}&`;
    } else if (filter.department && filter.department.id) {
      url += `department_id=${filter.department.id}&`;
    }

    return AxiosClient.get(`${BASE_URL}${url}`)
      .then((res) => {
        commit("SET_DEVICE_RECONCILIATION_COUNTS", res.data);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  getReconciliationStatusesCount({ commit }) {
    const filter = this.getters["filters/filters"];
    let url = `/devices/count/reconciliation?`;

    if (filter.subCategory && filter.subCategory.id) {
      url += `subcategory_id=${filter.subCategory.id}&`;
    } else if (filter.category && filter.category.id) {
      url += `category_id=${filter.category.id}&`;
    } else if (filter.department && filter.department.id) {
      url += `department_id=${filter.department.id}&`;
    }

    return AxiosClient.get(`${BASE_URL}${url}`)
      .then((res) => {
        commit("SET_DEVICE_RECONCILIATION_STATUSES_COUNTS", res.data);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  getStatusesCount(
    { commit, rootState },
    { filter = null, reset = true } = {}
  ) {
    let url = `/devices/count/status?`;
    filter = filter ? filter : this.getters["filters/filters"];

    const currentRoute: any = router.currentRoute;
    const routeName = currentRoute._value.name;
    const allowedFilters = RouteFilters[routeName]?.allowedFilters || [];

    const root: any = rootState;
    const floorId = root.locations?.floor?.id;

    if (filter.subCategory && filter.subCategory.id) {
      url += `subcategory_id=${filter.subCategory.id}&`;
    } else if (filter.category && filter.category.id) {
      url += `category_id=${filter.category.id}&`;
    } else if (filter.department && filter.department.id) {
      url += `department_id=${filter.department.id}&`;
    }
    if (
      filter.reconciliationStatuses &&
      filter.reconciliationStatuses.length > 0
    ) {
      url += `reconciliation_status=${filter.reconciliationStatuses.join(
        ","
      )}&`;
    }

    const config = { hideProgressBar: false };
    if (filter.hideProgressBar) {
      // url += `hideProgressBar=true&`;
      config.hideProgressBar = true;
    }

    // if (floorId && allowedFilters.includes(FilterScopeEnums.FLOOR_NUMBER)) {
    //   url += `floor_id=${floorId}&`;
    // }

    return AxiosClient.get(`${BASE_URL}${url}`, { params: config })
      .then((res) => {
        if (reset) {
          commit("SET_DEVICE_STATUSES_COUNTS", res.data);
        }
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  getDeviceFiles(
    { commit },
    { device_id, reset = false, pagination = { limit: 25, offset: 0 } }
  ) {
    if (reset) commit("SET_DEVICE_FILES", null);

    if (!pagination) pagination = { limit: 25, offset: 0 };
    const url = `${BASE_URL}/devices/${device_id}/files?limit=${pagination.limit}&offset=${pagination.offset}&`;

    return AxiosClient.get(url)
      .then((res) => {
        commit("SET_DEVICE_FILES", res.data);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  getDevicesProximity(
    { commit, rootState },
    { pagination = { limit: 25, offset: 0 }, set = true } = {}
  ) {
    if (!pagination) pagination = { limit: 25, offset: 0 };
    const filter = this.getters["filters/filters"];

    const url = `${BASE_URL}/devices/proximity`;
    const root: any = rootState;

    const floorId = root.locations.floor.id;
    const floorNumber = root.locations.floor.floor_number;
    const latitude = 40.6397662;
    const longitude = -73.999;

    const params = {
      floor_id: floorId,
      floor_number: floorNumber,
      latitude,
      longitude,
      ...pagination,
    };

    if (filter.deviceStatuses) {
      params["status"] = filter.deviceStatuses;
    }
    if (filter.locationUnit) {
      params["unit_id"] = filter.locationUnit.id;
    }

    if (filter.category && filter.category.id) {
      params["category_id"] = filter.category.id;
    } else if (filter.department && filter.department.id) {
      params["department_id"] = filter.department.id;
    }

    return AxiosClient.get(url, {
      params,
    })
      .then((res) => {
        if (set) {
          commit("SET_DEVICES_PROXIMITY", res.data.data);
        }
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  async getAllDevicesProximity({ commit, dispatch }) {
    const limit = 1000;

    const res = await dispatch("getDevicesProximity", {
      pagination: {
        offset: 0,
        limit,
      },
      set: false,
    });
    let devices = res.data;
    const total = res.total_elements;
    const totalNumPage = Math.ceil(total / limit);

    for (let i = 1; i < totalNumPage; i++) {
      const res2 = await dispatch("getDevicesProximity", {
        pagination: {
          offset: i * limit,
          limit,
        },
        set: false,
      });

      devices = [...devices, ...res2.data];
    }
    commit("SET_DEVICES_PROXIMITY", devices);
  },

  downloadDocument(_, { document_id, entity_id, project_id }) {
    return AxiosClient.get(`${BASE_URL}/documents`, {
      params: { document_id, entity_id, project_id },
      responseType: "blob",
    })
      .then((res) => {
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
};
