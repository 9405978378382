<template>
  <div class="util__summary__top__right">
    <div class="util__summary__top__right__titles">
      <div class="">
        <h6>Utilization rate over time</h6>
        <BaseSelect v-model="groupBy" :options="options" />
      </div>
      <button v-show="false">
        <BaseIcon icon="device/download" />
        <span>Download Report</span>
      </button>
    </div>
    <div class="util__summary__top__right__charts">
      <MultiLineChart :chart-data="chartDataList" />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch, onMounted } from "vue";
import store from "@/store";
import BaseIcon from "@/components/svg/BaseIcon";
import MultiLineChart from "../../resusables/charts/MultiLineChart";
import { helpers } from "@/composables/helpers";
import BaseSelect from "@/components/form/BaseSelect";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "DeviceUtilizationUsageChart",
  components: { BaseSelect, MultiLineChart, BaseIcon },
  setup() {
    const { convertToHrs } = helpers();

    const route = useRoute();

    const options = ["day", "week", "month"];

    const utilGroupBy = computed(() => {
      return store.getters["devices/utilGroupBy"];
    });

    const groupBy = ref(utilGroupBy.value);

    onMounted(() => {
      store.commit("devices/SET_UTIL_GROUP_BY", "day");
    });

    watch(utilGroupBy, (value) => {
      groupBy.value = value;
    });

    watch(groupBy, (value) => {
      store.commit("devices/SET_UTIL_GROUP_BY", value);
      store.dispatch("analytics/getAnalyticsUtilizationRates", {
        device_id: route.params.id,
      });
    });

    const device = computed(() => {
      return store.getters["devices/device"];
    });

    const utilizationRates = computed(() => {
      return store.getters["analytics/utilizationRates"];
    });

    const updateChartList = () => {
      let labels = utilizationRates.value.labels;
      if (
        groupBy.value === "week" &&
        labels.length > 0 &&
        labels[0].includes("-")
      ) {
        labels = labels.map((label, index) => `Week ${index + 1}`);
      }
      return {
        labels,
        datasets: [
          {
            label: "Rate",
            data: utilizationRates.value.rates,
            borderColor: "#016667",
            backgroundColor: "#016667",
            yAxisID: "y",
          },
        ],
      };
    };

    let chartDataList = ref(updateChartList());

    watch(utilizationRates, () => {
      chartDataList.value = updateChartList();
    });

    const rate = computed(() => {
      const idleHrs = convertToHrs(utilizationRates.value.hours.idle);
      const usageHrs = convertToHrs(utilizationRates.value.hours.usage);

      return Math.round((usageHrs / (idleHrs + usageHrs)) * 100);
    });

    return {
      rate,
      groupBy,
      options,
      device,
      chartDataList,
    };
  },
});
</script>
